@media screen and (max-width: 650px) {
    .intro__logo {
        margin-bottom: 24px;
        padding-top: 64px;
    }

    .intro__logo svg {
        height: 36px;
        width: 36px;
    }

    .intro__header {
        font-size: 36px;
        line-height: 117%;
        margin: 0 auto 16px;
        max-width: 275px;
        width: 100%;
    }

    .intro__call {
        font-size: 16px;
        margin: 0 auto 40px;
        max-width: 420px;
        padding: 0 5%;
        width: 100%;
    }

    .password-controls {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        margin: 0 auto 48px;
        max-width: 420px;
        width: 100%;
    }

    .switcher {
        text-align: left;
    }

    .switcher:not(:last-child) {
        margin-right: 0;
    }

    .switcher__checkbox:checked ~ .switcher__label {
        transform: translate(-23px);
    }

    .password {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 72px);
        max-width: 300px;
        width: 100%;
    }

    .action-buttons {
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 400px) {
    .password-controls {
        gap: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, auto);
    }
}