* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    align-items: center;
    background-color: var(--wood-smoke);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    font-family: 'Roboto Mono', monospace;
}

main {
    margin: 0 auto;
    max-width: 800px;
    padding: 0 24px;
    text-align: center;
    width: 100%;
}

.intro__logo {
    margin-bottom: 32px;
}

.intro__header {
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 16px;
}

.intro__call {
    color: #6E767C;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 48px;
    padding: 0 10%;
    text-align: center;
}

.password-controls {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
}

.password-container {
    margin-bottom: 64px;
}

.password {
    display: inline-grid;
    gap: 12px;
    grid-template-columns: repeat(8, 56px);
    grid-template-rows: repeat(2, 72px);
    justify-items: center;
}

.password__row {
    color: #000;
    font-size: 22px;
    font-weight: 600;
}

.password__char {
    align-items: center;
    background-color: #212224; /* #252729 */
    border-radius: 8px;
    color: #414549; /* 565759 */
    display: flex;
    height: 100%;
    font-family: 'Roboto Mono', monospace;
    font-size: 36px;
    font-variant-numeric: tabular-nums;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    transition: background-color 350ms ease, color 350ms ease;
    width: 100%;
}

.password__char.password__char--g {
    background-color: rgba(44, 201, 182, 0.1);
    color: #218d80;
}

.switcher {
    background-color: rgba(118, 118, 118, 0.1);
    /* border: 2px solid var(--outer-space); */
    border-radius: 23px;
    cursor: pointer;
    height: 46px;
    padding: 0 21px 0 45px;
    position: relative;
}

.switcher:not(:last-child) {
    margin-right: 16px;
}

.switcher--checked {
    background-color: rgba(44, 201, 182, 0.1);
    border-color: var(--turquoise);
}

.switcher__checkbox {
    opacity: 0;
    position: absolute;
}

.switcher__checkbox:checked ~ .switcher__label {
    color: var(--turquoise);
    transform: translate(-23px);
}

.switcher__checkbox:checked ~ .switcher__tick--unchecked {
    opacity: 0;
    transform: translate(20px, -50%);
}

.switcher__checkbox:checked ~ .switcher__tick--checked {
    opacity: 1;
    transform: translate(0, -50%);
}

.switcher__label {
    color: var(--shuttle-gray);
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 46px; /* 36px */
    transition: transform 250ms ease;
    user-select: none;
}

.switcher__tick {
    font-size: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 250ms ease, transform 350ms ease;
}

.switcher__tick--unchecked {
    left: 13px;
}

.switcher__tick--checked {
    opacity: 0;
    right: 13px;
    transform: translate(-20px, -50%);
}

.action-buttons {
    margin: 0 auto;
    position: relative;
    width: fit-content;
}

.generate-password {
    background-color: #363B41;
    border: none;
    border-radius: 28px;
    color: #7F8990;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
    height: 56px;
    outline: none;
    padding: 0 32px;
    position: relative;
    transition: opacity 450ms ease;
}

.generate-password:disabled,
.repeat-password-generation:disabled {
    cursor: not-allowed;
    opacity: 0.25;
}

.generate-password--clipboard {
    background-color: rgba(44, 201, 182, 0.2);
    color: #2CC9B6;
    padding: 0 72px 0 32px;
}

.generate-password--clipboard + .repeat-password-generation {
    display: inline-flex;
}

.repeat-password-generation {
    background-color: transparent;
    border: none;
    cursor: copy;
    display: none;
    outline: none;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 450ms ease;
}